import {Dispatch} from 'redux'

let nextCallId = 1
const callIdMap: {[name: string]: number} = {}

export const callAPI =
  ({SUCCESS, REQUEST, FAILURE, NAME}: ApiAction, ...args: any[]) =>
  (dispatch: Dispatch, _getState: Function, {serverApi, flowApi}) => {
    const api = serverApi.get(NAME)

    const callId = nextCallId++
    callIdMap[NAME] = callId
    dispatch({type: REQUEST, args})

    return api(...args)
      .then((payload: any) => {
        const newest = callIdMap[NAME] === callId
        dispatch({type: SUCCESS, payload, args, newest})
        return payload
      })
      .catch((error: any) => {
        const newest = callIdMap[NAME] === callId
        dispatch({type: FAILURE, error, args, newest})
        console.log(error) // for tests
        flowApi?.reportError?.(error)
        throw error
      })
  }

export const createActions = (NAME: string): ApiAction => ({
  REQUEST: `${NAME}_REQUEST`,
  SUCCESS: `${NAME}_SUCCESS`,
  FAILURE: `${NAME}_FAILURE`,
  NAME,
})

interface ApiAction {
  REQUEST: string
  SUCCESS: string
  FAILURE: string
  NAME: string
}
