import React from 'react'
import {Actions, State} from '../types/state'

export const WidgetStateContext = React.createContext<State>(null)

export function useWidgetState<T>(selector: (state: State) => T): T {
  return selector(React.useContext<State>(WidgetStateContext))
}

export const WidgetActionsContext = React.createContext<Actions>(null)

export const useWidgetActions = () => React.useContext<Actions>(WidgetActionsContext)
