import {StatusFilter} from '@wix/events-types'
import {EVENT_FILTER_TYPE} from '@wix/wix-events-commons-statics'

export const getStatusFilter = (filterType: EVENT_FILTER_TYPE): StatusFilter => {
  const filterMap = {
    [EVENT_FILTER_TYPE.UPCOMING_AND_PAST]: StatusFilter.FUTURE_AND_PAST,
    [EVENT_FILTER_TYPE.PAST]: StatusFilter.PAST_ONLY,
    [EVENT_FILTER_TYPE.UPCOMING]: StatusFilter.FUTURE_ONLY,
  }

  return filterMap[filterType] ?? StatusFilter.FUTURE_ONLY
}
