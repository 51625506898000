import {asyncActionsFactory} from '@wix/wix-events-commons-statics'
import {ControllerFlowAPI, IWixAPI} from '@wix/yoshi-flow-editor'
import {State} from '../types/state'
import {Api} from '../utils/api'

type Extra = {
  serverApi: Api
  wixCodeApi: IWixAPI
  compId: string
  flowAPI: ControllerFlowAPI
}

export const createAsyncAction = asyncActionsFactory<State, Extra>()
