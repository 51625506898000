import {IStyleParam, wixColorParam} from '@wix/tpa-settings'
import {LIST_LAYOUT, MOBILE_LIST_LAYOUT} from '@wix/wix-events-commons-statics'
import {getDefaultFont} from '../../../../../settings-commons/utils/style'
import {GetDefaultValueProps, widgetStyles} from '../../../stylesParams'
import {ADDITIONAL_MOBILE_LIST_LAYOUT, WidgetText} from './constants'
import {WidgetFontDefault, cardsFontDefaults, commonFontDefaults} from './defaults'
import {FontConfig, StudioParamsMap} from './mapper'

interface GetFontConfigParams {
  params: GetDefaultValueProps
  widgetText: WidgetText
  map: StudioParamsMap
}

interface GetWidgetFontDefaultParams extends GetFontConfigParams {
  hover?: boolean
  defaultParam?: IStyleParam
}

export const getFontConfig = ({params, widgetText, map}: GetFontConfigParams): FontConfig => {
  const {getStyleParamValue, isMobile} = params
  const listLayout = getStyleParamValue(widgetStyles.listLayout) as LIST_LAYOUT

  if (isMobile) {
    const mobileListLayout = getStyleParamValue(widgetStyles.mobileListLayout) as MOBILE_LIST_LAYOUT

    if (isGridMobile(mobileListLayout, listLayout)) {
      return map.mobile[ADDITIONAL_MOBILE_LIST_LAYOUT.GRID][widgetText]
    }

    return map.mobile[mobileListLayout][widgetText]
  }

  return map.desktop[listLayout][widgetText]
}

export const getWidgetFontDefault = ({params, widgetText, hover, defaultParam, map}: GetWidgetFontDefaultParams) => {
  const {getStyleParamValue, isMobile} = params
  const listLayout = getStyleParamValue(widgetStyles.listLayout) as LIST_LAYOUT

  const defaults = (isCards(listLayout) ? cardsFontDefaults : commonFontDefaults)[widgetText]
  const defaultFontSize = getDefaultFontSize(defaults, isMobile, hover)

  const {font, size, hoverFont, hoverSize, staticFontSize, hoverStaticFontSize} =
    getFontConfig({
      params,
      widgetText,
      map,
    }) ?? {}
  const [fontParam, sizeParam, staticSize] = hover
    ? [hoverFont, hoverSize, hoverStaticFontSize]
    : [font, size, staticFontSize]
  const fontValue = fontParam && getStyleParamValue(fontParam)
  const sizeValue = staticSize ?? (sizeParam && getStyleParamValue(sizeParam))

  if (fontValue) {
    const {value: _value, preset: _preset, ...rest} = fontValue

    return sizeValue ? getDefaultFont(defaults.preset, {...rest, size: sizeValue})(params) : fontValue
  }

  if (hover && defaultParam) {
    const defaultParamValue = getStyleParamValue(defaultParam)
    const {value: _value, preset: _preset, ...rest} = defaultParamValue

    return sizeValue ? getDefaultFont(defaults.preset, {...rest, size: sizeValue})(params) : defaultParamValue
  }

  return getDefaultFont(defaults.preset, {size: sizeValue ?? defaultFontSize})(params)
}

const getDefaultFontSize = (defaults: WidgetFontDefault, isMobile: boolean, hover?: boolean) => {
  const {fontSize, mobileFontSize, hoverFontSize, hoverMobileFontSize} = defaults

  if (isMobile) {
    return hover ? hoverMobileFontSize : mobileFontSize
  }

  return hover ? hoverFontSize : fontSize
}

export const getWidgetFontColorDefault = ({
  params,
  widgetText,
  hover,
  defaultParam,
  map,
}: GetWidgetFontDefaultParams) => {
  const {getStyleParamValue} = params
  const {color, hoverColor} = getFontConfig({params, widgetText, map}) ?? {}
  const colorParam = hover ? hoverColor : color
  const colorValue = colorParam && getStyleParamValue(colorParam)

  if (colorValue) {
    return colorValue
  }

  if (hover && defaultParam) {
    return getStyleParamValue(defaultParam)
  }

  return wixColorParam('color-5')(params)
}

export const getSocialShareDefault = (params: GetDefaultValueProps) => {
  const {getStyleParamValue} = params
  const listLayout = getStyleParamValue(widgetStyles.listLayout) as LIST_LAYOUT

  if (isCards(listLayout) || isSideBySide(listLayout)) {
    return getStyleParamValue(widgetStyles.listHeaderTitleColor)
  }
  if (isList(listLayout)) {
    return getStyleParamValue(widgetStyles.listContentTextColor)
  }

  return wixColorParam('color-5')(params)
}

const isCards = (listLayout: LIST_LAYOUT) => [LIST_LAYOUT.GRID, LIST_LAYOUT.NEW_GRID].includes(listLayout)

const isList = (listLayout: LIST_LAYOUT) => listLayout === LIST_LAYOUT.LIST

const isSideBySide = (listLayout: LIST_LAYOUT) => listLayout === LIST_LAYOUT.SIDE_BY_SIDE

const isFullMobile = (mobileListLayout: MOBILE_LIST_LAYOUT) => mobileListLayout === MOBILE_LIST_LAYOUT.FULL

const isGridMobile = (mobileLayout: MOBILE_LIST_LAYOUT, desktopLayout: LIST_LAYOUT) =>
  isFullMobile(mobileLayout) && !isList(desktopLayout)
