import {updateSiteSettings} from '../../../../commons/actions/site-settings'
import {reloadEvents} from './events'
import {settingsTabChanged} from './settings-panel'

export const SettingsUpdatedActionType = {
  SETTINGS: 'SETTINGS',
  SITE_SETTINGS: 'SITE_SETTINGS',
  SELECT_EVENT: 'SELECT_EVENT',
  DISPLAY_EVENT: 'DISPLAY_EVENT',
  HIDE_EVENT: 'HIDE_EVENT',
  HIDE_EVENT_PENDING: 'HIDE_EVENT/pending',
  NAVIGATE_TO_PAGE: 'NAVIGATE_TO_PAGE',
  NAVIGATE_TO_PAGE_PENDING: 'NAVIGATE_TO_PAGE/pending',
  SETTINGS_TAB_CHANGED_PENDING: 'SETTINGS_TAB_CHANGED/pending',
  SETTINGS_TAB_CHANGED: 'SETTINGS_TAB_CHANGED',
  RELOAD_EVENTS: 'RELOAD_EVENTS',
}

interface SettingsUpdateAction {
  type: string
  [key: string]: any
}

export const updateSettings = (action: SettingsUpdateAction) => async (dispatch: Function) => {
  switch (action.type) {
    case SettingsUpdatedActionType.SITE_SETTINGS:
      dispatch(updateSiteSettings(action.settings))
      break
    case SettingsUpdatedActionType.SETTINGS_TAB_CHANGED:
    case SettingsUpdatedActionType.SETTINGS_TAB_CHANGED_PENDING:
      const navigationInfo = action.payload
      dispatch(settingsTabChanged(navigationInfo))
      break
    case SettingsUpdatedActionType.RELOAD_EVENTS:
      dispatch(reloadEvents())
      break
    default:
      break
  }
}
