import {ISiteTextPreset} from '@wix/tpa-settings'
import {WidgetText} from './constants'

export interface WidgetFontDefault {
  preset: keyof ISiteTextPreset
  fontSize: number
  mobileFontSize?: number
  hoverFontSize?: number
  hoverMobileFontSize?: number
}

type WidgetFontDefaults = Record<WidgetText, WidgetFontDefault>

export const commonFontDefaults: WidgetFontDefaults = {
  [WidgetText.LIST_TITLE]: {
    preset: 'Page-title',
    fontSize: 36,
    mobileFontSize: 28,
  },
  [WidgetText.TITLE]: {
    preset: 'Body-M',
    fontSize: 26,
    hoverFontSize: 26,
    mobileFontSize: 20,
    hoverMobileFontSize: 20,
  },
  [WidgetText.DATE]: {
    preset: 'Body-M',
    fontSize: 16,
    hoverFontSize: 16,
    mobileFontSize: 16,
    hoverMobileFontSize: 16,
  },
  [WidgetText.LOCATION]: {
    preset: 'Body-M',
    fontSize: 16,
    hoverFontSize: 16,
    mobileFontSize: 16,
    hoverMobileFontSize: 16,
  },
  [WidgetText.DESCRIPTION]: {
    preset: 'Body-M',
    fontSize: 16,
    hoverFontSize: 15,
    mobileFontSize: 16,
    hoverMobileFontSize: 15,
  },
  [WidgetText.LINK]: {
    preset: 'Body-M',
    fontSize: 14,
    hoverFontSize: 14,
    mobileFontSize: 14,
    hoverMobileFontSize: 14,
  },
}

export const cardsFontDefaults: WidgetFontDefaults = {
  ...commonFontDefaults,
  [WidgetText.DESCRIPTION]: {
    ...commonFontDefaults[WidgetText.DESCRIPTION],
    hoverFontSize: 15,
  },
}
