import {createAction} from '@reduxjs/toolkit'
import {GetCommonState, SiteSettingsSettings} from '../types/state'
import {callAPI, createActions} from './data-action-helper'

export const setSiteSettings = createAction<SiteSettingsSettings>('SET_SITE_SETTINGS')

export const GET_SITE_SETTINGS = createActions('GET_SITE_SETTINGS')
export const UPDATE_SITE_SETTINGS = createActions('UPDATE_SITE_SETTINGS')

export const getSiteSettings = () => (dispatch: Function) => dispatch(callAPI(GET_SITE_SETTINGS))

export const updateSiteSettings =
  (payload: Partial<SiteSettingsSettings>) => (dispatch: Function, getState: GetCommonState) => {
    const settings = {...getState().siteSettings.settings, ...payload}

    return dispatch(callAPI(UPDATE_SITE_SETTINGS, settings))
  }
