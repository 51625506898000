import {LoadableComponent} from '@loadable/component'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import React, {useEffect, useState} from 'react'

export const LoadableSuspenseWithPreviousFallback = ({Component}: {Component: LoadableComponent<any>}) => {
  const [LoadedComponent, setLoadedComponent] = useState<LoadableComponent<any>>(null)

  useEffect(() => {
    Component.load().then(() => setLoadedComponent(Component))
  }, [Component])

  if (!LoadedComponent) {
    return <Placeholder />
  }

  return <LoadedComponent />
}

const Placeholder = () => {
  const {
    dimensions: {height},
  } = useEnvironment()
  return <div style={{height}}></div>
}
